<template>
  <v-card
    class="mx-n3 mx-sm-16 px-0 px-sm-4 px-md-8 flex-fill"
    max-width="500"
    flat
    tile
    outlined
  >
    <v-card-title class="my-3 my-sm-6 my-md-12 text-h4 font-weight-bold justify-center">
      {{ gc_langText.account.title[gc_lang] }}
    </v-card-title>

    <v-card-text class="py-0">

      <!--  form 박스: 비밀번호 변경  -->
      <v-form
        v-if="pwChange"
        ref="pwForm"
        v-model="formValid"
      >

        <!--  비밀번호  -->
        <v-text-field
          class="rounded-0"
          v-model="form.pw"
          :label="gc_langText.common.account.pw[gc_lang]"
          @focus="form.pw = ''"
          @input="gm_form_isPwEqual"
          @click:append="show.pw = !show.pw"
          :rules="[gc_rules.required, gc_rules.pwLength, gc_rules.pwEqual]"
          :append-icon="show.pw ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show.pw ? 'text' : 'password'"
          :disabled="requesting"
          validate-on-blur
          outlined
          dense
        />

        <!--  비밀번호 확인  -->
        <v-text-field
          class="rounded-0"
          v-model="form.pwr"
          :label="gc_langText.common.account.pwr[gc_lang]"
          @focus="form.pwr = ''"
          @input="gm_form_isPwEqual"
          @click:append="show.pwr = !show.pwr"
          :rules="[gc_rules.required, gc_rules.pwLength, gc_rules.pwEqual]"
          :append-icon="show.pwr ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show.pwr ? 'text' : 'password'"
          :disabled="requesting"
          validate-on-blur
          outlined
          dense
        />

      </v-form>

      <!--  form 박스  -->
      <v-form
        v-else
        ref="form"
        v-model="formValid"
      >

        <!--  회원 타입  -->
        <v-radio-group
          v-model="form.userType"
          class="mt-n4 mt-md-n8"
          disabled
          row
        >
          <v-radio
            class="mr-0"
            :label="gc_langText.common.account.usertype['1'][gc_lang]"
            value="1"
          />
          <v-radio
            class="mr-0 ml-4"
            :label="gc_langText.common.account.usertype['0'][gc_lang]"
            value="0"
          />
          <v-radio
            class="mr-0 ml-4"
            :label="gc_langText.common.account.usertype['2'][gc_lang]"
            value="2"
          />
        </v-radio-group>

        <!--  이름  -->
        <v-text-field
          class="rounded-0"
          v-model="form.name"
          :label="gc_langText.common.account.name[gc_lang]"
          :rules="[gc_rules.required]"
          :disabled="requesting"
          validate-on-blur
          outlined
          dense
        />

        <!--  이메일 입력  -->
        <v-text-field
          class="rounded-0"
          v-model="form.mail"
          :label="gc_langText.common.account.mail[gc_lang]"
          validate-on-blur
          outlined
          readonly
          dense
        />

        <!--  소속  -->
        <v-text-field
          class="rounded-0"
          v-model="form.affiliation"
          :label="gc_langText.common.account.affiliation[gc_lang]"
          :rules="[gc_rules.required]"
          :disabled="requesting"
          validate-on-blur
          outlined
          dense
        />

        <!--  진료과  -->
        <v-text-field
          class="rounded-0"
          v-model="form.medicalDepartment"
          :label="gc_langText.common.account.medicalDepartment[gc_lang]"
          :rules="[gc_rules.required]"
          :disabled="requesting"
          validate-on-blur
          outlined
          dense
        />

        <!--  휴대폰 번호  -->
        <v-text-field
          class="rounded-0"
          v-model="form.phone"
          @input="gm_form_onlyNumber"
          :rules="[gc_rules.required, gc_rules.phone]"
          :label="gc_langText.common.account.cellPhoneNumber[gc_lang]"
          :placeholder="gc_langText.common.account.withOutText[gc_lang]"
          :disabled="requesting || form.phoneAuth"
          validate-on-blur
          outlined
          dense
        />

      </v-form>
    </v-card-text>

    <v-card-actions class="px-4 pt-0 flex-column">

      <v-btn
        ref="submit"
        class="text-h6 c-tt-none"
        @click="submit"
        elevation="0"
        color="cyan"
        :dark="!requesting"
        :disabled="requesting"
        :loading="requesting"
        block
        large
        tile
      >
        {{ gc_langText.account.btn.apply[gc_lang] }}
      </v-btn>

      <v-btn
        class="my-6 ml-0 text-h6 c-tt-none"
        elevation="0"
        @click="changeMode"
        :disabled="requesting"
        block
        large
        outlined
        tile
      >
        {{
          pwChange ?
            gc_langText.account.btn.changeInfo[gc_lang] :
            gc_langText.account.btn.changePw[gc_lang]
        }}
      </v-btn>

    </v-card-actions>

  </v-card>
</template>

<script>
export default {
  name: "Account",

  data: vm => ({
    // 비밀번호 변경 모드
    pwChange: false,

    // 폼 입력 데이터
    form: {
      userType: vm.$store.state.user.type + '', // 서버에서 숫자 타입으로 받음... 문자열로 변환
      name: vm.$store.state.user.name,
      mail: vm.$store.state.user.email,
      pw: '',
      pwr: '',
      phone: vm.$store.state.user.phone,
      affiliation: vm.$store.state.user.position, // 소속
      medicalDepartment: vm.$store.state.user.department, // 진료과
    },

    // 비밀번호 입력란 보임 여부
    show: {
      pw: false,
      pwr: false
    },

    // 폼 내부 입력 값들 유효 상태
    formValid: true,

    // 요청 상태
    requesting: false
  }),

  watch: {
    pwChange() {
      const form = this.form

      form.pw = ''
      form.pwr = ''
    }
  },

  methods: {
    // 폼 입력값 전송
    submit() {
      const it = this
      const form = it.pwChange ? it.$refs.pwForm : it.$refs.form

      form.validate()
      if (it.formValid) {
        const id = this.$store.state.user.id
        const params = {}

        if (it.pwChange) {
          params.password = this.form.pw
        } else {
          params.name = this.form.name
          params.phone = this.form.phone
          params.position = this.form.affiliation
          params.department = this.form.medicalDepartment
          params.type = this.form.userType
        }

        this.api_updateUser(params, id)
      }
    },

    // 일반 및 비밀번호 모드 변경
    changeMode() {
      const it = this
      const form = this.pwChange ? this.$refs.pwForm : this.$refs.form

      form.resetValidation()
      it.pwChange = !it.pwChange
    }
  }
}
</script>

<style lang="sass" scoped>

</style>